import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "626px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ab0316f2aa224104d1eb01950cc62b6d/af590/before-after_4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC6klEQVQoz13M+UvTcRwG8P0RUUa1Zm06bTUT02YXhhRZVlBE2rC0i0YHVD912TpGtigMOm1Om2dNl2uGmsWQDsq0jKxcS3IltlV+t7n7czyxSb/0hgeehze8RAFGVwcYuxjkvPx3JKp1B4Jaj39CK8Si2iBwOsCZLkCpRBAEudfnu+D1+U///vNH+93l0rpcLm28C+Pj5YIgVHh9vhUij9N53e1wwPPFiW9vevH5+Qt8evUaI+8G8GtwCO4hBzzDX/MnQqHNfr8f8QheLzxeAaMeT6KHKUEgFILP79eJrAsyKlrnyNAmkYYbMpcSsyqXPJyVTGyZC2OPCpZEO5SZ6FyUuwzAOkYpAERHx77HnlZVk576JuL2/IyNvnsbFtxj8d9xkUWh1LdOn4XqVWtiB/ZV8Jslm7h12gx+W5XD71zXsJZjZbAtXbkcQCGNRADC6PjIB/7irIY/LNfy4REn7zq0ifzs74iDJ0XmdIXeIpaiPkNBrhwt5oZza1G7IAW3NEX8jqmK1W8rhHXK9ATofP0cdZrd1KW7zO2Wq+hpuIKxXjuvubCXfOmzTYIdOSq9feMWtMzLIqYCJW89tR416WKY5ku4cXceM2dnwZaUPAnaumAsXkf1+Wm8dMMadF4qw9DBXdx+tZwEQhOTYHfpHn33kUN4cHhXrFql5LUKJW8q0fCWrTv5vaTZrE0qx4N58xPgYGMbbhQVUpNuPx9+38cHHxv4QIOO/3jZQeIaY/SkqDlr8UXDhpWoPKOOGHNV9K40lbZYH1FbrYmaxRLSJktHe1Z2AnT09sFquE1e2rsoZZwNdNawZw3n2ZNWYzQBUnpCdF8mrzSKk1GVJkd90kzUyeRorKyAWX8WzRIpLFI52nNy8gBsxH/3caAfjYYb+OoYSmxKyRnRfWVGZrNEurMxSbzNLJmrrpOlqq8VrFJX5eepm+amqC0paTvas7OnApgRC4dLwsFgUTQSKSYkVgzgX4ooJdspY4q//gErVt652xwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Before After",
            "title": "Before After",
            "src": "/static/ab0316f2aa224104d1eb01950cc62b6d/af590/before-after_4.png",
            "srcSet": ["/static/ab0316f2aa224104d1eb01950cc62b6d/772e8/before-after_4.png 200w", "/static/ab0316f2aa224104d1eb01950cc62b6d/e17e5/before-after_4.png 400w", "/static/ab0316f2aa224104d1eb01950cc62b6d/af590/before-after_4.png 626w"],
            "sizes": "(max-width: 626px) 100vw, 626px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div className="col-lg-8 my-5">
      <h2>{`Summary`}</h2>
      <p>{`Esteban handled a brand redesign, product development and launch of family of products based on Quinoa, with a 20 SKUs including the prepararion and design of FDA compliant packaging for exports.  `}</p>
    </div>
    <div className="col-lg-8 my-5 py-5 border-top">
      <h2>{`1. Research and strategy`}</h2>
      <p>{`Esteban researched market trends which led to an initial hypothesis that in comparison to gluten-free products based on rice, quinoa offered a clear differentiation due to a higher protein content. `}</p>
      <p>{`The strategy was expanded by interviewing industry experts, including ProEcuador diplomats, networking with startups, attending local and international trade events and meeting with target user groups to validate and adjust the hypothesis.  `}</p>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1e5e01358c08f9972aea5311f651f0a1/bcec7/cereales_andinos-07.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBP/EABYBAQEBAAAAAAAAAAAAAAAAAAADBP/aAAwDAQACEAMQAAABioqGjEjpstDAf//EABkQAAIDAQAAAAAAAAAAAAAAAAABAhETEP/aAAgBAQABBQJLlCLLMomUTKJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFRABAQAAAAAAAAAAAAAAAAAAIDH/2gAIAQEABj8CNH//xAAcEAACAgIDAAAAAAAAAAAAAAAAARFhQfBRcZH/2gAIAQEAAT8h6CD0NHI9wbSJOGUlLP/aAAwDAQACAAMAAAAQDyhA/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPxAqK8//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/ECMmf//EAB4QAQACAQQDAAAAAAAAAAAAAAEAESExQVGRYYHx/9oACAEBAAE/ELdChsTEo96sWc+QMrYeRkYgUOa3EqYe9Jug8rmYtfc+xP/Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/1e5e01358c08f9972aea5311f651f0a1/4b190/cereales_andinos-07.jpg",
                "srcSet": ["/static/1e5e01358c08f9972aea5311f651f0a1/e07e9/cereales_andinos-07.jpg 200w", "/static/1e5e01358c08f9972aea5311f651f0a1/066f9/cereales_andinos-07.jpg 400w", "/static/1e5e01358c08f9972aea5311f651f0a1/4b190/cereales_andinos-07.jpg 800w", "/static/1e5e01358c08f9972aea5311f651f0a1/bcec7/cereales_andinos-07.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b8a5e2606174dee7ce3659ef7db165ae/bcec7/celifood_4.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAWOsMaWMqjnSJygX/8QAGhAAAwEBAQEAAAAAAAAAAAAAAQIDABESBP/aAAgBAQABBQIUY0p6Vm+2gZyM/Wpz1g3dKa0zkq3/xAAVEQEBAAAAAAAAAAAAAAAAAAAgQf/aAAgBAwEBPwGD/8QAGREAAQUAAAAAAAAAAAAAAAAAAAEQETFB/9oACAECAQE/AdIFpv/EACAQAAIBAwQDAAAAAAAAAAAAAAABIQIREhATIzFBUbH/2gAIAQEABj8CwqpW2vBipXsfEXklF/mlWUjS6P/EABoQAQEBAQEBAQAAAAAAAAAAAAERACExQYH/2gAIAQEAAT8hgH8I6fucQhpgCHluYi68h93R959kyOw3EdZ1xAMXcWw7/9oADAMBAAIAAwAAABDzAML/xAAaEQACAgMAAAAAAAAAAAAAAAAAAREhEDGB/9oACAEDAQE/EJmo2k6HvmP/xAAYEQEBAQEBAAAAAAAAAAAAAAABABEhsf/aAAgBAgEBPxDE6hpt6Q3/xAAeEAEAAgMAAgMAAAAAAAAAAAABABEhMUFhgXGhsf/aAAgBAQABPxBkIGtB8Ta/mYYNQqL23PBhtKVYmvNYmT6RFb78l/eDZKKus+opn+HQ+vFRAAeBrPJVBBRaVFd1Bv8AZ//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/b8a5e2606174dee7ce3659ef7db165ae/4b190/celifood_4.jpg",
                "srcSet": ["/static/b8a5e2606174dee7ce3659ef7db165ae/e07e9/celifood_4.jpg 200w", "/static/b8a5e2606174dee7ce3659ef7db165ae/066f9/celifood_4.jpg 400w", "/static/b8a5e2606174dee7ce3659ef7db165ae/4b190/celifood_4.jpg 800w", "/static/b8a5e2606174dee7ce3659ef7db165ae/bcec7/celifood_4.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1496bd0e38b0dd1cbf8eaafdb738cf12/bcec7/cereales_andinos-10.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHs7zLLeiFwsA//xAAbEAACAwADAAAAAAAAAAAAAAASEwECAwAQEf/aAAgBAQABBQJtTKC4up6xZ2bPO//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwEj/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/ASv/xAAcEAAABgMAAAAAAAAAAAAAAAAAARESIUEQIDH/2gAIAQEABj8CashMOsKRHyhOn//EABwQAAEFAAMAAAAAAAAAAAAAAAEAESExQSBRgf/aAAgBAQABPyEFh5ZEXazq1gn2sTbgnTebiOH/2gAMAwEAAgADAAAAEOsAAP/EABcRAQADAAAAAAAAAAAAAAAAAAEAEBH/2gAIAQMBAT8QMYov/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQAQEf/aAAgBAgEBPxBcgG//xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMSBBYXH/2gAIAQEAAT8QSd92tuAbL0fMEAnHAEavdzk5ZkaYAfQ0dbv7iYE8F2Q7rw//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/1496bd0e38b0dd1cbf8eaafdb738cf12/4b190/cereales_andinos-10.jpg",
                "srcSet": ["/static/1496bd0e38b0dd1cbf8eaafdb738cf12/e07e9/cereales_andinos-10.jpg 200w", "/static/1496bd0e38b0dd1cbf8eaafdb738cf12/066f9/cereales_andinos-10.jpg 400w", "/static/1496bd0e38b0dd1cbf8eaafdb738cf12/4b190/cereales_andinos-10.jpg 800w", "/static/1496bd0e38b0dd1cbf8eaafdb738cf12/bcec7/cereales_andinos-10.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8be3f6a208fe76a7d46396167978f569/bcec7/cereales_andinos-12.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABgBAAIDAAAAAAAAAAAAAAAAAAAEAQID/9oADAMBAAIQAxAAAAGbqybUYmajDeMHYBKf/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAAyEjERIy/9oACAEBAAEFAqV2GvWAomZzFKFXZur+WGf/xAAYEQEAAwEAAAAAAAAAAAAAAAABABESEP/aAAgBAwEBPwGqJmIZ5//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/ASM//8QAHhAAAgEDBQAAAAAAAAAAAAAAAREAAhAyEiFBUWH/2gAIAQEABj8CpJ9lS6iImJ25VhqTmRv/AP/EABoQAQADAAMAAAAAAAAAAAAAAAEAESExQaH/2gAIAQEAAT8hqqqSD0AqIBqTIaDvXwQreFHseC4sYakGP//aAAwDAQACAAMAAAAQ99eD/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAERIRBBUf/aAAgBAwEBPxCzZLSFGhdx/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQAxESH/2gAIAQIBAT8QXZQfW0Tt/8QAHBABAAMBAAMBAAAAAAAAAAAAAQARIUExcbHh/9oACAEBAAE/ELwUUO5BmJ15dfsOhptzhUZqig9EbT8mgoCovlKSf1t33sA6vjsvqu19hptAKA9s/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/8be3f6a208fe76a7d46396167978f569/4b190/cereales_andinos-12.jpg",
                "srcSet": ["/static/8be3f6a208fe76a7d46396167978f569/e07e9/cereales_andinos-12.jpg 200w", "/static/8be3f6a208fe76a7d46396167978f569/066f9/cereales_andinos-12.jpg 400w", "/static/8be3f6a208fe76a7d46396167978f569/4b190/cereales_andinos-12.jpg 800w", "/static/8be3f6a208fe76a7d46396167978f569/bcec7/cereales_andinos-12.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/787e50aa51c57abed773440c447d8d58/bcec7/celifood_7.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABs96ZkilLRwsxB//EABsQAAIBBQAAAAAAAAAAAAAAAAABAgMQERIx/9oACAEBAAEFAkYKinsPg7//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BK//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwEj/8QAFxABAAMAAAAAAAAAAAAAAAAAARAgIv/aAAgBAQAGPwKMhf8A/8QAGxABAAMBAAMAAAAAAAAAAAAAAQAhMREQQWH/2gAIAQEAAT8h56hae/DQR9iQOLZfL2aiVHZ//9oADAMBAAIAAwAAABC8977/xAAYEQACAwAAAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPxAusn//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EA//xAAdEAEAAwEAAgMAAAAAAAAAAAABABEhMUFRcZGh/9oACAEBAAE/EBueimD6+YAFtV1mu3DRbf3Gqyi95Alb5b/aOQwzja9wU98EAjtP/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/787e50aa51c57abed773440c447d8d58/4b190/celifood_7.jpg",
                "srcSet": ["/static/787e50aa51c57abed773440c447d8d58/e07e9/celifood_7.jpg 200w", "/static/787e50aa51c57abed773440c447d8d58/066f9/celifood_7.jpg 400w", "/static/787e50aa51c57abed773440c447d8d58/4b190/celifood_7.jpg 800w", "/static/787e50aa51c57abed773440c447d8d58/bcec7/celifood_7.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e117a7978a52353d335ecabce6216981/e5166/cereales_andinos-01.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBQL/xAAYAQACAwAAAAAAAAAAAAAAAAABAgADBP/aAAwDAQACEAMQAAABt1+7dOjPTosk4VxAw//EABwQAAICAgMAAAAAAAAAAAAAAAECABEDBBITIv/aAAgBAQABBQIkdfJbJqDWFjGkymmDmWY/p//EABgRAAIDAAAAAAAAAAAAAAAAAAERAAIQ/9oACAEDAQE/ARdBR7//xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/AUYKf//EABwQAAICAgMAAAAAAAAAAAAAAAABESECICIxgf/aAAgBAQAGPwKEce0XlHheTgpaNs//xAAbEAEAAwEBAQEAAAAAAAAAAAABABEhQTFRgf/aAAgBAQABPyHCD8nmS/SpkBdGSVaHBqUDJO1DMtclAcbyDHvamiN3P//aAAwDAQACAAMAAAAQqDA8/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAEQITHw8f/aAAgBAwEBPxCkCXejzH//xAAZEQADAAMAAAAAAAAAAAAAAAAAAREQIVH/2gAIAQIBAT8QtKE9G1j/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRkf/aAAgBAQABPxB+Fap58x5/Moj37sNmDbbqItYxgl5ouULUcKK62+wqbFezoZWnHQdQXMTOfMn/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/e117a7978a52353d335ecabce6216981/4b190/cereales_andinos-01.jpg",
                "srcSet": ["/static/e117a7978a52353d335ecabce6216981/e07e9/cereales_andinos-01.jpg 200w", "/static/e117a7978a52353d335ecabce6216981/066f9/cereales_andinos-01.jpg 400w", "/static/e117a7978a52353d335ecabce6216981/4b190/cereales_andinos-01.jpg 800w", "/static/e117a7978a52353d335ecabce6216981/e5166/cereales_andinos-01.jpg 1200w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="col-lg-8 my-5 border-top py-5">
      <h2>{`2. Product Design`}</h2>
      <p>{`Existing products were organized under a family that allowed positioning as a gluten-free and healthy alternative.`}</p>
      <ul>
        <li parentName="ul">{`Quinoa Facil for flours, flakes, soups since they required extra steps in cooking before serving.`}</li>
        <li parentName="ul">{`Quinoa Crunch later turned to Quinoa Munch for breakfast cereals `}</li>
        <li parentName="ul">{`Quinola Quinoa Granola was kept with the old packaging until it would become gluten-free `}</li>
      </ul>
      <h4>{`Branding`}</h4>
      <p>{`Esteban designed a new brand, packaging and company culture.`}</p>
      <p>{`The brand was initially designed as `}<em parentName="p">{`Celifood`}</em>{` but renamed locally as `}<em parentName="p">{`Cereales Andinos`}</em>{`. The brand concept was: "modern and raw" in direct contrast to highly illustrated and artificial imagery found the the category. `}</p>
      <p>{`By collaborating in a photoshoot with Almeria-based photographer Marcelo Mausse to develop a style that showed ingredients in it's natural form, the packaging allowed a contrats with a bright magenta to stand out in the shelves. `}</p>
      <p>{`The innovation focus implemented in the company also meant that anything that was needed could be designed: from promotional materials to sale sheets and product specs were all prepared and improved after key meetings and events. `}</p>
      <h4>{`Certification`}</h4>
      <p>{`The focus on `}<em parentName="p">{`special dietary needs`}</em>{` required improvements in all production processes.
Working along with a Production Manager and Quality Manager we prepared a plan that would lead to iterating updates on products as well as implementing HACCP, NonGMO and Gluten Free certifications.`}</p>
      <h4>{`Versioning`}</h4>
      <p>{`Esteban worked alongside the production team and oversaw versioning and development of the products to align them to the strategy, all product iterations were tested locally and some prototypes taken to international fairs for validation from business partners.`}</p>
      <p>{`v1 of the Quinoa Crunch had problems with inconsistent quality, used milk as part of their ingredients not vegan, had a short shelf life ~6 months.`}</p>
      <p>{`The v2 solved all the shortcomings of the original product, increasing its shelf life to 1 year a soft requirement for exports, improving its quality, taste and nutrional tables. `}</p>
      <p>{`The v3 was prepared to move towards organic ingredients and a higher nutrional value by increasing the quantity of quinoa. The result was test driven in the SIAL Fair in Paris and SIAL Fair in Toronto. `}</p>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2323266b12eae87c9995dcd67f7a8b66/bcec7/cereales_andinos-06.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQD/2gAMAwEAAhADEAAAAb5eDmslY+M5FkCL/8QAGxAAAwADAQEAAAAAAAAAAAAAAQIDABETEiH/2gAIAQEAAQUCN10tx5xpJqaoU6xGckIMEOP8b//EABcRAAMBAAAAAAAAAAAAAAAAAAAQESH/2gAIAQMBAT8ByEf/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAdEAABBAIDAAAAAAAAAAAAAAAAARARIQIxMmGx/9oACAEBAAY/AuVIXLb7En0vNkIP/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAITFhUf/aAAgBAQABPyG2pUnMKrdZDEQf3u+Qqu56jvQfGYDt81mmjI+GFBwGb//aAAwDAQACAAMAAAAQLx+8/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPxAaVQv/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAgEBPxDbEVD6hn//xAAdEAEBAAMAAgMAAAAAAAAAAAABEQAhQTFRYXGh/9oACAEBAAE/EJTQUWPr8ciNUoDXO/OMboBC7LlIGWMrodHq4/7NIWg0+PWCrBARUOcxLOrN3xiCEHTzPvCG0hOxc//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/2323266b12eae87c9995dcd67f7a8b66/4b190/cereales_andinos-06.jpg",
                "srcSet": ["/static/2323266b12eae87c9995dcd67f7a8b66/e07e9/cereales_andinos-06.jpg 200w", "/static/2323266b12eae87c9995dcd67f7a8b66/066f9/cereales_andinos-06.jpg 400w", "/static/2323266b12eae87c9995dcd67f7a8b66/4b190/cereales_andinos-06.jpg 800w", "/static/2323266b12eae87c9995dcd67f7a8b66/bcec7/cereales_andinos-06.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f267b372061ca6ed773a719dab81425d/bcec7/cereales_andinos-13.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAQEAAwAAAAAAAAAAAAAAAAQCAQMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAH1ZaZcqZonnQdIP//EABoQAAMBAAMAAAAAAAAAAAAAAAABAgMxM0L/2gAIAQEAAQUC8saK6xl61GU06h8//8QAFREBAQAAAAAAAAAAAAAAAAAAIEH/2gAIAQMBAT8Bg//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABgQAQEAAwAAAAAAAAAAAAAAAAEQABFR/9oACAEBAAY/AqU13BZ//8QAGhAAAwEBAQEAAAAAAAAAAAAAAAERITFBUf/aAAgBAQABPyF8vrhqpqGhqFk8M4nYdDodVxpNqv4MP//aAAwDAQACAAMAAAAQl9f/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAwEBPxADUZ//xAAWEQEBAQAAAAAAAAAAAAAAAAAQATH/2gAIAQIBAT8QJh//xAAbEAEBAQEBAAMAAAAAAAAAAAABEQBBITFxwf/aAAgBAQABPxDy8qH7ZmsNcjbivOpdPU3yVAVfzMvcVgF8lwNAKxDNW//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/f267b372061ca6ed773a719dab81425d/4b190/cereales_andinos-13.jpg",
                "srcSet": ["/static/f267b372061ca6ed773a719dab81425d/e07e9/cereales_andinos-13.jpg 200w", "/static/f267b372061ca6ed773a719dab81425d/066f9/cereales_andinos-13.jpg 400w", "/static/f267b372061ca6ed773a719dab81425d/4b190/cereales_andinos-13.jpg 800w", "/static/f267b372061ca6ed773a719dab81425d/bcec7/cereales_andinos-13.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3611be0d6f3bced5c2e32854c8d92f25/bcec7/celifood_11.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMCBAH/xAAXAQADAQAAAAAAAAAAAAAAAAABAgME/9oADAMBAAIQAxAAAAHk1WctM3YMvdg1TCp//8QAGxABAQEAAgMAAAAAAAAAAAAAAgERACEDEBL/2gAIAQEAAQUCOxW9FWyHL8qo+E8zCe/X/8QAGREAAgMBAAAAAAAAAAAAAAAAAAECEBES/9oACAEDAQE/AeWqkaz/xAAXEQADAQAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/ATFf/8QAGxAAAgMAAwAAAAAAAAAAAAAAAAECESEQMUH/2gAIAQEABj8CqJuSR4WuxtmiYlx//8QAGxABAQEBAAMBAAAAAAAAAAAAAREAMSFBgaH/2gAIAQEAAT8hTiFnvOo8BwO66/TM9OL7dbRV0PI5AfXQWOrckU3/2gAMAwEAAgADAAAAEBAQPv/EABoRAAMAAwEAAAAAAAAAAAAAAAABESFRYcH/2gAIAQMBAT8QsblKzFXvjHuP/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAExEWH/2gAIAQIBAT8QUxnEY6f/xAAcEAEBAQEAAgMAAAAAAAAAAAABEQAhMWFBcbH/2gAIAQEAAT8QWMevhMLFu0MffncG5ZgG7CH4Tc4YR8V8T1lNSXsmdx631HiYh+VBa4eoxld//9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/3611be0d6f3bced5c2e32854c8d92f25/4b190/celifood_11.jpg",
                "srcSet": ["/static/3611be0d6f3bced5c2e32854c8d92f25/e07e9/celifood_11.jpg 200w", "/static/3611be0d6f3bced5c2e32854c8d92f25/066f9/celifood_11.jpg 400w", "/static/3611be0d6f3bced5c2e32854c8d92f25/4b190/celifood_11.jpg 800w", "/static/3611be0d6f3bced5c2e32854c8d92f25/bcec7/celifood_11.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f63952222fe929820d0c0e6c964c9d42/bcec7/cereales_andinos-05.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIDAf/aAAwDAQACEAMQAAABszTp1IOZc41oCP/EABkQAAMAAwAAAAAAAAAAAAAAAAABAhIiMf/aAAgBAQABBQIYqH2UjUounNZn/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAIQETH/2gAIAQMBAT8BKGyP/8QAGREAAQUAAAAAAAAAAAAAAAAAAAECEBIx/9oACAECAQE/AULDcj//xAAcEAACAgIDAAAAAAAAAAAAAAAAARExEKEhMpH/2gAIAQEABj8Co6l7KXpW8MhQLhH/xAAdEAEAAwACAwEAAAAAAAAAAAABABEhMVFBYZFx/9oACAEBAAE/IU997Zmnwe5hq/KZFaW3hlGVC+aMAGp9nLBnolLfiWroT//aAAwDAQACAAMAAAAQ9wA+/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxBu4izGCn//xAAbEQABBAMAAAAAAAAAAAAAAAABABARIWGR8P/aAAgBAgEBPxAIEkI4dtXs3//EAB4QAQEAAgICAwAAAAAAAAAAAAERACFBUTGhcdHw/9oACAEBAAE/EGtTpOn9MKa05D3ilWOzwHXjNocASyfPORGQYaPWCNjuS+sgY0r3NdusFBAU1xRlFLz95//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/f63952222fe929820d0c0e6c964c9d42/4b190/cereales_andinos-05.jpg",
                "srcSet": ["/static/f63952222fe929820d0c0e6c964c9d42/e07e9/cereales_andinos-05.jpg 200w", "/static/f63952222fe929820d0c0e6c964c9d42/066f9/cereales_andinos-05.jpg 400w", "/static/f63952222fe929820d0c0e6c964c9d42/4b190/cereales_andinos-05.jpg 800w", "/static/f63952222fe929820d0c0e6c964c9d42/bcec7/cereales_andinos-05.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3272c40bec5ce00a8998f1f974bceb3e/bcec7/cereales_andinos-04.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIFAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABqvPM07qytACgP//EABsQAQEBAAIDAAAAAAAAAAAAAAIBAwARIzEy/9oACAEBAAEFAlhE4fLpvStXA9GuqDeJVc9mfP8A/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQMBAT8BI//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwEr/8QAHBAAAgMAAwEAAAAAAAAAAAAAAAECERIhMUFx/9oACAEBAAY/AnPXpeqVnSHcLsWYpIRyyIvh/8QAHBAAAwACAwEAAAAAAAAAAAAAAAERITFBUXHw/9oACAEBAAE/IWVjaFNYv0jrVUSqJ4fRU5x6ZA9I3c66NZn2Ds8D/9oADAMBAAIAAwAAABCkwP8A/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBEEH/2gAIAQMBAT8QTRTf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQQVH/2gAIAQIBAT8QwSFR/8QAHhABAAICAgMBAAAAAAAAAAAAAQARITFBcVFhwYH/2gAIAQEAAT8Qbmwg40HPm4u71g6Z3+2SgqBZt4rDyYiDELGvCVQMotuBbm+4bYwhQZN/YhaALuAo7tx2Psttd3qf/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/3272c40bec5ce00a8998f1f974bceb3e/4b190/cereales_andinos-04.jpg",
                "srcSet": ["/static/3272c40bec5ce00a8998f1f974bceb3e/e07e9/cereales_andinos-04.jpg 200w", "/static/3272c40bec5ce00a8998f1f974bceb3e/066f9/cereales_andinos-04.jpg 400w", "/static/3272c40bec5ce00a8998f1f974bceb3e/4b190/cereales_andinos-04.jpg 800w", "/static/3272c40bec5ce00a8998f1f974bceb3e/bcec7/cereales_andinos-04.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/783bd4057aeb026cfac7ebd5bd4b53a4/bcec7/cereales_andinos-14.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIEBQP/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQMC/9oADAMBAAIQAxAAAAGnLvCdbKB4zro1oAP/xAAaEAACAwEBAAAAAAAAAAAAAAABAgAREgMh/9oACAEBAAEFAjbtVRHyqvrmBUGZxPqjRCgD/8QAFxEAAwEAAAAAAAAAAAAAAAAAAhEgQf/aAAgBAwEBPwEFsf/EABYRAQEBAAAAAAAAAAAAAAAAAAEQEv/aAAgBAgEBPwHIRn//xAAdEAACAQQDAAAAAAAAAAAAAAAAEQECECFRMUFh/9oACAEBAAY/Alo4kw5Pdimo6EZt/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAMUEhUWH/2gAIAQEAAT8hdq0eScY7pnOkADB60RGLoOsScG5vk44p0ZN//9oADAMBAAIAAwAAABC4EP3/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEQ/9oACAEDAQE/EFpZkc//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAgEBPxAC4pRM/8QAHhABAAIBBQEBAAAAAAAAAAAAAQARITFBUWFxsdH/2gAIAQEAAT8Qr7gkyPhKjuBYvi+oeqos26YURTKG332Cq6AI2O+c5llzF+9ywtkp+wxqGpX5BAwOZ//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/783bd4057aeb026cfac7ebd5bd4b53a4/4b190/cereales_andinos-14.jpg",
                "srcSet": ["/static/783bd4057aeb026cfac7ebd5bd4b53a4/e07e9/cereales_andinos-14.jpg 200w", "/static/783bd4057aeb026cfac7ebd5bd4b53a4/066f9/cereales_andinos-14.jpg 400w", "/static/783bd4057aeb026cfac7ebd5bd4b53a4/4b190/cereales_andinos-14.jpg 800w", "/static/783bd4057aeb026cfac7ebd5bd4b53a4/bcec7/cereales_andinos-14.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="col-lg-8 my-5 border-top py-5">
      <h2>{`3. Launch`}</h2>
      <p>{`The change in packaging and style caught the attention of the retailers and target audience allowing the product to grow rapidly in shelf space.`}</p>
      <p>{`Locally top of mind was created through sponsoring sporting events, participating in the startup scene and through key placements in country clubs. `}</p>
      <p>{`Online the product quickly gained followers in the healthy living and gluten free communities growing in all social media and leading to radio and television interviews. `}</p>
      <p>{`Cereales Andinos became the locally produced healthier alternative for breakfast that challenged imports from Kellogs and Nestlé, gaining equivalent shelf space in the main retailers Supermaxi, Mi Comisariato, TIA among many others. `}</p>
      <p>{`The growing prescence caught the attention of exporters interested in taking the product overseas. To promote the product we attended international fairs and eventually we exported to Chile, USA, South Africa, Lebanon, and Panama. `}</p>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d9e7acd3053f5c04de003207bf13ae15/bcec7/celifood_5.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABcBAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAeN0sWkisy8dhIOn/8QAGxAAAgMAAwAAAAAAAAAAAAAAAREAAgMSITL/2gAIAQEAAQUCHqgZOfeJty1vVAlZktCOf//EABkRAAEFAAAAAAAAAAAAAAAAAAABEBExgf/aAAgBAwEBPwGDGSj/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIRAxAhMUH/2gAIAQEABj8CKyPVoTxxqXpyUda//8QAHBAAAgMAAwEAAAAAAAAAAAAAAAERITFBUWGB/9oACAEBAAE/IaekYCXFCp8fBTZ2PdgKoaSViUWMwcJG0n//2gAMAwEAAgADAAAAEAQ/fP/EABkRAAMAAwAAAAAAAAAAAAAAAAABESFB8f/aAAgBAwEBPxCpTPZoVNKf/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAExEVH/2gAIAQIBAT8Q1QxdFR0//8QAHRABAAICAwEBAAAAAAAAAAAAAQARITFBUWGBcf/aAAgBAQABPxAuK9N6p7goVC0FFeTv5HL2+vZXqhQHkOM74hWGuhSvzi/OooXVypuWqz5JnvKbS4TBqf/Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/d9e7acd3053f5c04de003207bf13ae15/4b190/celifood_5.jpg",
                "srcSet": ["/static/d9e7acd3053f5c04de003207bf13ae15/e07e9/celifood_5.jpg 200w", "/static/d9e7acd3053f5c04de003207bf13ae15/066f9/celifood_5.jpg 400w", "/static/d9e7acd3053f5c04de003207bf13ae15/4b190/celifood_5.jpg 800w", "/static/d9e7acd3053f5c04de003207bf13ae15/bcec7/celifood_5.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/bbdc94a95eb1fb7e4ae174cb43f5165c/bcec7/celifood_6.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBQEE/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGrRz4r15kaXyHO4M//xAAdEAACAgIDAQAAAAAAAAAAAAABAgADERIEEyEy/9oACAEBAAEFAtcF2Bl1rbLZkK5MYeuOteP8hRP/xAAXEQADAQAAAAAAAAAAAAAAAAAAEBGB/9oACAEDAQE/AZDX/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQIBAT8BY//EAB4QAAIBAwUAAAAAAAAAAAAAAAARAQMhQRASUZHR/9oACAEBAAY/Atzyypw4LRgcOT0uiV1r/8QAHBABAAIDAQEBAAAAAAAAAAAAAQARITFBUXGR/9oACAEBAAE/ITF2e04F/aShpD1Fytwl34m4XYn6QtgXdtRLlp55BXXW2f/aAAwDAQACAAMAAAAQtNj/AP/EABoRAQACAwEAAAAAAAAAAAAAAAEAMRARIUH/2gAIAQMBAT8QT1cA1WPuP//EABoRAAEFAQAAAAAAAAAAAAAAAAABEBEhQVH/2gAIAQIBAT8QhpXBG//EAB4QAQADAQACAwEAAAAAAAAAAAERITEAQWFRcaGB/9oACAEBAAE/EGB4aKRYT1n5wciUSXJ1piEhaX65oIBFwrWOUlBaDzp8ZH7x4w4sy8cpT1HSs5yOU4Qr6c+xd0Db/O//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/bbdc94a95eb1fb7e4ae174cb43f5165c/4b190/celifood_6.jpg",
                "srcSet": ["/static/bbdc94a95eb1fb7e4ae174cb43f5165c/e07e9/celifood_6.jpg 200w", "/static/bbdc94a95eb1fb7e4ae174cb43f5165c/066f9/celifood_6.jpg 400w", "/static/bbdc94a95eb1fb7e4ae174cb43f5165c/4b190/celifood_6.jpg 800w", "/static/bbdc94a95eb1fb7e4ae174cb43f5165c/bcec7/celifood_6.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/61cc34d40751256134d3f1aa91635dd2/bcec7/celifood_1.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABm11qLiUM2G0oA//EABsQAAICAwEAAAAAAAAAAAAAAAECAAMEESIh/9oACAEBAAEFAlx91rjBocYCFtQNzZZ1YYvKFff/xAAXEQEBAQEAAAAAAAAAAAAAAAAAARJB/9oACAEDAQE/AZGa4j//xAAXEQEAAwAAAAAAAAAAAAAAAAAAEBFB/9oACAECAQE/AVtj/8QAHhAAAgIBBQEAAAAAAAAAAAAAAAERMRICECEyYaH/2gAIAQEABj8CWrKDuXJjjdC4l+FfBCh7f//EAB0QAQEBAAEFAQAAAAAAAAAAAAERACExQVFhccH/2gAIAQEAAT8hongR7acF37mZXtqiP6aSApyLdAngmMP3m0mJdV3/2gAMAwEAAgADAAAAEFAHg//EABcRAQEBAQAAAAAAAAAAAAAAAAEAITH/2gAIAQMBAT8QJNii8X//xAAXEQEBAQEAAAAAAAAAAAAAAAABACEx/9oACAECAQE/EHJMx7f/xAAdEAEAAgMBAAMAAAAAAAAAAAABESEAMUFhcYHB/9oACAEBAAE/EEabI3HZN1koTsIQj7MgcUESbywCKgUp5XMhlXAFXPG5widpKVM7/cEzAdZHbq/PnGBBhtnmf//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/61cc34d40751256134d3f1aa91635dd2/4b190/celifood_1.jpg",
                "srcSet": ["/static/61cc34d40751256134d3f1aa91635dd2/e07e9/celifood_1.jpg 200w", "/static/61cc34d40751256134d3f1aa91635dd2/066f9/celifood_1.jpg 400w", "/static/61cc34d40751256134d3f1aa91635dd2/4b190/celifood_1.jpg 800w", "/static/61cc34d40751256134d3f1aa91635dd2/bcec7/celifood_1.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/18e4d17e0c41cd429bf97ff7e095575c/bcec7/cereales_andinos-08.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe3N0AlCg//EABkQAAEFAAAAAAAAAAAAAAAAAAABEBEgIv/aAAgBAQABBQJTVYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAXEAEAAwAAAAAAAAAAAAAAAAAxABAg/9oACAEBAAY/AiFOv//EABoQAAIDAQEAAAAAAAAAAAAAAAERACExEGH/2gAIAQEAAT8hphGNpUV6i94RL2+//9oADAMBAAIAAwAAABCIBwD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAgEBPxBj/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFhEEH/2gAIAQEAAT8Qsq7l4lwsBeYdz4aM7goLTcXTlg0pK6+f/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/18e4d17e0c41cd429bf97ff7e095575c/4b190/cereales_andinos-08.jpg",
                "srcSet": ["/static/18e4d17e0c41cd429bf97ff7e095575c/e07e9/cereales_andinos-08.jpg 200w", "/static/18e4d17e0c41cd429bf97ff7e095575c/066f9/cereales_andinos-08.jpg 400w", "/static/18e4d17e0c41cd429bf97ff7e095575c/4b190/cereales_andinos-08.jpg 800w", "/static/18e4d17e0c41cd429bf97ff7e095575c/bcec7/cereales_andinos-08.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4b8c6acbb3297b9245306306ea7e7e22/bcec7/cereales_andinos-09.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB5GKgJNgoH//EABkQAAIDAQAAAAAAAAAAAAAAAAABEBESAv/aAAgBAQABBQK40LI1xeRoSj//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAEAAQUAAAAAAAAAAAAAAAABABAgISKR/9oACAEBAAY/Aq5Wary3/8QAGxABAAEFAQAAAAAAAAAAAAAAAQAQESFBYYH/2gAIAQEAAT8hS6JcvBExYjwnIW4BtfIV4AYp/9oADAMBAAIAAwAAABCbML7/xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8QK//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAAMAAgIDAAAAAAAAAAAAAAABETFBUWEhgdH/2gAIAQEAAT8QcNpXoyIryIYnstNziigxD6ic28Eed0+i3hRTjVfZD//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/4b8c6acbb3297b9245306306ea7e7e22/4b190/cereales_andinos-09.jpg",
                "srcSet": ["/static/4b8c6acbb3297b9245306306ea7e7e22/e07e9/cereales_andinos-09.jpg 200w", "/static/4b8c6acbb3297b9245306306ea7e7e22/066f9/cereales_andinos-09.jpg 400w", "/static/4b8c6acbb3297b9245306306ea7e7e22/4b190/cereales_andinos-09.jpg 800w", "/static/4b8c6acbb3297b9245306306ea7e7e22/bcec7/cereales_andinos-09.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ea4f664e080ca924891c23a10589dad3/bcec7/celifood_8.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAEAA//aAAwDAQACEAMQAAAB5a1y2xVNolBf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAIBIDEyQf/aAAgBAQABBQKjakHXz//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABkQAAMAAwAAAAAAAAAAAAAAAAABMRAgIf/aAAgBAQAGPwLRcWIQh//EABsQAAICAwEAAAAAAAAAAAAAAAABESEQMXFB/9oACAEBAAE/IUoIIxCjosNWgmoUG8ouH//aAAwDAQACAAMAAAAQeMcD/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAExEP/aAAgBAwEBPxB3VD//xAAXEQADAQAAAAAAAAAAAAAAAAAAATEQ/9oACAECAQE/EFNdP//EAB0QAQADAAEFAAAAAAAAAAAAAAEAESExEFFhcdH/2gAIAQEAAT8QAbOGxKwjwNVb7s7PQ6Cnlv7DOZpZTT7huTrg0T//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Before After",
                "title": "Before After",
                "src": "/static/ea4f664e080ca924891c23a10589dad3/4b190/celifood_8.jpg",
                "srcSet": ["/static/ea4f664e080ca924891c23a10589dad3/e07e9/celifood_8.jpg 200w", "/static/ea4f664e080ca924891c23a10589dad3/066f9/celifood_8.jpg 400w", "/static/ea4f664e080ca924891c23a10589dad3/4b190/celifood_8.jpg 800w", "/static/ea4f664e080ca924891c23a10589dad3/bcec7/celifood_8.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0df32f9bf4d9c23683d274d06b5f08d9/bcec7/celifood_2.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGJ47imdJrsXKD/xAAbEAACAgMBAAAAAAAAAAAAAAABAgAEERIhMf/aAAgBAQABBQKu/djBZRFrgg6iGuGYeHhxmf/EABYRAQEBAAAAAAAAAAAAAAAAAAERIP/aAAgBAwEBPwGDj//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAECAQE/AUj/xAAcEAACAgIDAAAAAAAAAAAAAAABAgAQESESMUH/2gAIAQEABj8Cdi3lBeXUZSJmMTrcExX/xAAbEAEAAgMBAQAAAAAAAAAAAAABACERMUFRkf/aAAgBAQABPyHdiaykAu5eIi67NlksxcNQXHpNnagPIvlKhoyTBNdZ/9oADAMBAAIAAwAAABCcN37/xAAZEQACAwEAAAAAAAAAAAAAAAAAEQEQITH/2gAIAQMBAT8QQ3oiK//EABgRAQADAQAAAAAAAAAAAAAAAAEAEDER/9oACAECAQE/EOlEV2OV/8QAHRABAQADAAIDAAAAAAAAAAAAAREAITFBUWGhwf/aAAgBAQABPxC18UN35n3hBBkBss7gsEV29OIQCHq058XFgFQJIbqdywFEqaE/cahrSTxrFFlTXVpgpeA0+mZ//9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Canada",
                "title": "Canada",
                "src": "/static/0df32f9bf4d9c23683d274d06b5f08d9/4b190/celifood_2.jpg",
                "srcSet": ["/static/0df32f9bf4d9c23683d274d06b5f08d9/e07e9/celifood_2.jpg 200w", "/static/0df32f9bf4d9c23683d274d06b5f08d9/066f9/celifood_2.jpg 400w", "/static/0df32f9bf4d9c23683d274d06b5f08d9/4b190/celifood_2.jpg 800w", "/static/0df32f9bf4d9c23683d274d06b5f08d9/bcec7/celifood_2.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3d6e5404c56cf8cd83d9e42f15ba969f/bcec7/celifood_3.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIEBQb/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAdl8WW9C4LDqoGBH/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAIBEwMREiL/2gAIAQEAAQUCVuxbd2ckP6tkvYjEslKFSn//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAXEQADAQAAAAAAAAAAAAAAAAAAEBIh/9oACAECAQE/AaNf/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhATFBcRASI//aAAgBAQAGPwLz66LCqnsmHkvUwTz/AP/EABwQAAIDAAMBAAAAAAAAAAAAAAABESExQVFhcf/aAAgBAQABPyHQrLisib4+EUUpFYZSBFW3MckJPS0QqzMoTr0fof/aAAwDAQACAAMAAAAQRyc//8QAGBEAAgMAAAAAAAAAAAAAAAAAABEQIVH/2gAIAQMBAT8QWFQj/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERITH/2gAIAQIBAT8QTSMeulZWf//EAB0QAQEAAgMAAwAAAAAAAAAAAAERACExUWFBcZH/2gAIAQEAAT8QYzcAEju0vf75gtdiQQceYgcMNpvuuc2wQOWyltwkhSQtR5T3CFM2v0K+6x9MA+ejE1C5EXdywOJZs7XrP//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "South Korea",
                "title": "South Korea",
                "src": "/static/3d6e5404c56cf8cd83d9e42f15ba969f/4b190/celifood_3.jpg",
                "srcSet": ["/static/3d6e5404c56cf8cd83d9e42f15ba969f/e07e9/celifood_3.jpg 200w", "/static/3d6e5404c56cf8cd83d9e42f15ba969f/066f9/celifood_3.jpg 400w", "/static/3d6e5404c56cf8cd83d9e42f15ba969f/4b190/celifood_3.jpg 800w", "/static/3d6e5404c56cf8cd83d9e42f15ba969f/bcec7/celifood_3.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/116763eb59fcd199533e613bf6ae47da/bcec7/celifood_10.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMCBAH/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB6Mc3g3ZXMrEMgV//xAAaEAEBAAMBAQAAAAAAAAAAAAABAgAREgMj/9oACAEBAAEFAo5W/R6dOelIFfQd5ElVc8XDo//EABcRAAMBAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BMd//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8BK//EABsQAAMBAAMBAAAAAAAAAAAAAAABIRECEjEy/9oACAEBAAY/AosHnyXRpemLzEXiLaPrIQ//xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhMUFh/9oACAEBAAE/IXRrQzp8/RXcGO0crsg1r8F3R9uzps+nqF1YjF4Mk0R//9oADAMBAAIAAwAAABCU6H//xAAYEQEBAQEBAAAAAAAAAAAAAAABACERQf/aAAgBAwEBPxB12e17AX//xAAZEQEAAgMAAAAAAAAAAAAAAAABABARMUH/2gAIAQIBAT8QymoEJyv/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMWGBsUH/2gAIAQEAAT8QXEwLTQDLC5FU09fvsFNQVpbA4kY6OHrY8Juu6pz7zLDFWivxijUCxxjYjMEBReM+xKyy68J//9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "France",
                "title": "France",
                "src": "/static/116763eb59fcd199533e613bf6ae47da/4b190/celifood_10.jpg",
                "srcSet": ["/static/116763eb59fcd199533e613bf6ae47da/e07e9/celifood_10.jpg 200w", "/static/116763eb59fcd199533e613bf6ae47da/066f9/celifood_10.jpg 400w", "/static/116763eb59fcd199533e613bf6ae47da/4b190/celifood_10.jpg 800w", "/static/116763eb59fcd199533e613bf6ae47da/bcec7/celifood_10.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      